import { DuetCard, DuetHeading, DuetGrid, DuetCaption } from '@duetds/react';
import './index.scss';
import EuroIconGreen from '../../../svg/euro-IconGreen.svg';
import { useMediaQuery } from '../../Utils';
import Tooltip from '../Tooltip';
import { LangKey } from '../../../lang/LangKey';

type Props = {
  header: string,
  mainText: string,
  amount: string,
  withPadding?: boolean;
}

const CardWithInfo = (props: Props) => {
  const { header, mainText, amount, withPadding} = props;
  const isMobile = useMediaQuery('(max-width: 575px)');
  return (
    <DuetCard className={'infoCard'} background={'color-primary'}>
      <DuetGrid distribution="center" alignment="center" direction="vertical">
        <Tooltip absolute right={8} top={8} tooltipText={withPadding ? LangKey.TooltipsResultLeftFinance.t() : LangKey.TooltipsResultRightFinance.t()}/>
        <DuetCaption margin={'auto'} className={withPadding ? 'infoCardTextWithPadding' : 'infoCardText'}>{header}</DuetCaption>
        <img className={'euroIcon'} src={process.env.REACT_APP_PUBLIC_URL + EuroIconGreen} />
        <DuetCaption className={withPadding ? 'mainCardTextWithPadding' : 'mainCardText'} size="medium">{mainText}</DuetCaption>
        <DuetHeading className={'moneyValueWrapper'} color={'color-gray-lightest'} level={isMobile ? "h1" :"h2"} margin={'none'}>{amount}</DuetHeading>
      </DuetGrid>
    </DuetCard>
  )
};


export default CardWithInfo;
