type PageType = { p: string } & {[key: string]: string | undefined};

type PageModel<P extends PageType> = {
  page: P;
};

export default class Browser<P extends PageType> {
  private _wrapper: PageModel<P>;
  private readonly _defaultPage: P;

  private _settingHash: boolean = false;
  private _hashPrefix = '';

  constructor(wrapper: PageModel<P>) {
    this._wrapper = wrapper;
    this._defaultPage = wrapper.page;
    window.addEventListener('hashchange', this._setPageFromHash);
    this._setPageFromHash();
  }

  set page(value: P) {
    if (this._wrapper.page === value) return;
    window.scrollTo(window.scrollX, 0);
    const hash = this.getHash(value);
    if (hash !== window.location.hash.substr(1)) {
      this._settingHash = true;
      window.location.hash = this.getHash(value);
    }
    this._wrapper.page = value;
  }

  getHash = <P extends PageType>(value: P) => {
    let hash: string = '';
    const len = Object.keys(value).length;
    for (let i = 0; i < len; i += 1) {
      if (i === 0) {
        hash = value.p;
      } else {
        const v = value[`p${i}`];
        if (v !== undefined) {
          hash += `/${v}`;
        } else {
          break;
        }
      }
    }
    return this._hashPrefix + hash;
  }

  get page(): P {
    return this._wrapper.page;
  }

  private _setPageFromHash = () => {
    if (this._settingHash) {
      this._settingHash = false;
      return;
    }
    this._settingHash = false;

    if (window.location) {
      window.scrollTo(window.scrollX, 0);
      if (window.location.hash) {
        const hash = decodeURIComponent(window.location.hash);
        const urlParts = hash.substr(1 + this._hashPrefix.length).split(/\//);
        const pg: { [key: string]: string } = {};
        for (let i = 0; i < urlParts.length; i += 1) {
          const ind = i > 0 ? i : '';
          pg[`p${ind}`] = urlParts[i];
        }
        this._wrapper.page = pg as P;
      } else {
        this._wrapper.page = this._defaultPage;
      }
    }
  }

  back = () => {
    window.history.back();
  }

  /**
   * This value is skipped from parsing of the hash
   * I.e. hash = users/id/data, prefix = users/ => page is { id, data }
   * @param value
   */
  set hashPrefix(value: string) {
    if (this._hashPrefix === value) return;
    this._hashPrefix = value;
    this._setPageFromHash();
  }
}
