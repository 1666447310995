import { DuetGrid, DuetGridItem, DuetHeading } from '@duetds/react';
import './index.scss';
import { HBox, VBox } from '@simosol/boxes';
import DiagramItem from '../DiagramItems';
import DiagramIcon1 from '../../../../svg/diagramIcon_1.svg';
import DiagramIcon2 from '../../../../svg/diagramIcon_4.svg';
import DiagramIcon3 from '../../../../svg/diagramIcon_5.svg';
import { UrgentOperations } from '../../../../models/Valuation';
import { LangKey } from '../../../../lang/LangKey';

const DiagramIcon = [DiagramIcon1, DiagramIcon2, DiagramIcon3];
type Props = {
  urgentData: UrgentOperations[];
}

const NextFiveYearsDiagram = (props: Props) => {
  const { urgentData } = props;

  let urgentDataSumm: number = 0;
  urgentData.map( el => {
    urgentDataSumm = urgentDataSumm + el.cost;
  });

  const moneyPercent = urgentData.map(el => el.cost*100/urgentDataSumm);

  const localizationLabel = [
    LangKey.ResultPageDiagramsDiagramLabelTaimikonhoito.t(),
    LangKey.ResultPageDiagramsDiagramLabelEnsiharvennukset.t(),
    LangKey.ResultPageDiagramsDiagramLabelUudistustoimenpiteet.t(),
  ];

  const tooltipTexts = [
    LangKey.TooltipsNextFirstEl.t(),
    LangKey.TooltipsNextSecondEl.t(),
    LangKey.TooltipsNextThirdEl.t(),
  ];

  const priceForHectar = [350, 400, 1500];

  return (
    <>
      <div className={'NextFiveYearsDiagramRoot'}>
        <HBox  className={'nextFiveHeader'}>
          <DuetHeading level="h3" margin={'none'}>
            {LangKey.ResultPageDiagramsFiveYearsDiagramTitle.t()}
          </DuetHeading>
        </HBox>
        <VBox className={'nextFiveData'}>
          <DuetGrid distribution="left" direction="vertical" responsive>
            {urgentData.length === 0 ?
              <DuetHeading className={'nextFiveNoData'} level="h4" margin={'none'}>
                {LangKey.ResultPageDiagramsFiveYearsDiagramNone.t()}
              </DuetHeading>
              : urgentData.map((operation, indx) =>
                <DuetGridItem className={'itemStyle'} key={indx}>
                  <DiagramItem
                    icon={DiagramIcon[indx]}
                    label={localizationLabel[indx]}
                    addInfo={operation.area.toFixed(1) + 'ha'}
                    money={(+operation.area.toFixed(1) * priceForHectar[indx]).toFixed()}
                    isMoney
                    moneyPercent={moneyPercent[indx]}
                    tooltipsData={{tooltipText: tooltipTexts[indx], tooltipTextPos: 140}}
                  />
                </DuetGridItem>
              )}
          </DuetGrid>
        </VBox>
      </div>
    </>
  )
};

export default NextFiveYearsDiagram;
