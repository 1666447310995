import { DuetButton, DuetGrid, DuetGridItem, DuetHeading } from '@duetds/react';
import Card, { userData } from '../../ActiveComponent/Card'
import './index.scss';
import OneIcon from '../../../svg/oneIcon.svg';
import { useMediaQuery } from '../../Utils';
import { HBox } from '@simosol/boxes';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';
import { USERS_ROLE } from '../../../userRole';

type Props = {
  activeUser?: number;
};

const LandingPage = (props: Props) => {
  const { activeUser } = props;
  const isMobile = useMediaQuery('(min-width: 575px)');

  const setDefault = () => {
    app.clearActiveUser;
    app.browser.page = { p: 'estates' };
  };

  return (
    <>
      <DuetHeading className={'headingH2'} level={isMobile ? "h2" : "h3"}>{LangKey.MainPageTitle.t()}</DuetHeading>
      <HBox className={'tipsWrapper'} align={'center'}>
        <img className={'oneIconStyle'} src={process.env.REACT_APP_PUBLIC_URL + OneIcon}/>
        <DuetHeading className={'tipsStyle'} level={"h4"}>{LangKey.MainPageTips.t()}</DuetHeading>
      </HBox>
      <DuetGrid className={'contentWrapper'} distribution="center" mobile="center" direction={isMobile ? 'horizontal' : 'vertical'}>
        {USERS_ROLE.map((user: userData, index: number) =>
          <DuetGridItem className={'cardItemStyle'} margin={'none'} key={index}>
            <Card user={user} activeUser={activeUser} />
          </DuetGridItem>
        )}
      </DuetGrid>
      <HBox className={'exitSectionWrapper'}>
        <DuetHeading className={'exitSectionText'} level={isMobile ? "h4" : "h5"}>{LangKey.MainPageAdditionalInfo.t()}</DuetHeading>
        <DuetButton
          className={'duet-background-data-15 duet-radius-pill exitSectionButton'}
          padding={'none'}
          variation={"secondary"}
          color={"color-primary-lightest"}
          onClick={setDefault}
        >
          {LangKey.AppButtonsGuestButton.t()}
        </DuetButton>
      </HBox>
    </>
  );
};

export default LandingPage;
