import {
  DuetButton,
  DuetGrid,
  DuetGridItem,
  DuetHeading,
  DuetInput,
  DuetSpinner
} from '@duetds/react';
import './index.scss';
import { DuetInputEvent } from '@duetds/components/lib/types/components/duet-input/duet-input';
import { VBox } from '@simosol/boxes';
import { app } from '../../../models/App';
import { useMediaQuery } from '../../Utils';
import { LangKey } from '../../../lang/LangKey';
import { ESTATE_NOT_FOUND_CONTACT } from '../../../Config';
import { observer } from 'mobx-react-lite';

const EstateNotFound = observer(() => {
  const isMobile = useMediaQuery('(max-width: 769px)');
  const estateId = app.estateId;

  const onChange = (event: CustomEvent<DuetInputEvent>) => {
    app.estateId = event.detail.value;
  }

  return (
    <>
      {app.loading
        ? (<div className={'estateNotFoundSpinnerStyle'}>
            <DuetHeading className={'estateNotFoundSpinnerText'} level={"h2"}>{LangKey.EstateChooseLoading.t()}</DuetHeading>
            <DuetSpinner size={'large'} color="primary"/>
          </div>)
        : undefined
      }
      <div className={app.loading ? 'hideEstateData' : 'estateNotFoundRoot'}>
        <DuetGrid className={'estateNotFoundContactForm'} alignment="center" responsive>
          <DuetGridItem className={'estateNotFoundInputWrapper'} fill>
            <VBox className={'estateNotFoundInputTitleWrapper'} gap={8} align={'center'}>
              <DuetHeading
                className={'estateNotFoundInfoAboutMask'}
                level={isMobile ? 'h4' : 'h3'}
              >
                {LangKey.EstateNotFoundTitle.t()}
              </DuetHeading>
              <DuetButton
                className={'estateNotFoundContactLink'}
                variation={'plain'}
                url={ESTATE_NOT_FOUND_CONTACT}
              >
                {LangKey.EstateChooseContactLink.t()}
              </DuetButton>
              <DuetHeading className={'estateNotFoundInputTitle'} level={isMobile ? 'h4' : 'h3'} weight={'semibold'}>
                {LangKey.EstateChooseTips.t()}
              </DuetHeading>
            </VBox>
            <DuetGrid className={'estateNotFoundInputGrid'} alignment={"center"} direction={'vertical'}>
              <DuetGridItem>
                <DuetInput
                  onKeyPress={(e) => e.key === 'Enter' && !app.disabledButton ? app.getValuation() : ''}
                  onDuetChange={onChange}
                  className={'estateNotFoundContactInput'}
                  type={"text"}
                  placeholder={LangKey.EstateChooseEstateNum.t()}
                  disallow-pattern={"[^0-9,-]"}
                  labelHidden={true}
                  value={estateId}
                />
              </DuetGridItem>
              <DuetGridItem className={'estateNotFoundButtonWithTips'}>
                <DuetButton
                  onClick={() => app.getValuation()}
                  variation={"secondary"}
                  className={'duet-background-data-15 duet-radius-pill'}
                  color={"color-primary-lightest"}
                  wrapping={"none"}
                  fixed
                  disabled={app.disabledButton()}
                >
                  {LangKey.AppButtonsEstateSearchButton.t()}
                </DuetButton>
              </DuetGridItem>
            </DuetGrid>
          </DuetGridItem>
          <DuetGridItem className={'estateNotFoundImageWrapper'} fill>
            <img className={'estateNotFoundBottomImage'} src={process.env.REACT_APP_PUBLIC_URL + '/image/mainBack.png'} alt={'wood'}/>
          </DuetGridItem>
        </DuetGrid>
      </div>
    </>
  );
});

export default EstateNotFound;
