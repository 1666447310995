import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import proj4 from 'proj4';
import { configure } from 'mobx';
import { lang } from './lang/LangModel';

proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');

configure({
  enforceActions: 'never',
});

const run = async (appModel: { init: () => void }, app: React.ReactNode) => {
  appModel.init();
  // @ts-ignore
  await lang.init(process.env.REACT_APP_PUBLIC_URL + `/locales/${window.setLocalesForCalculator}.json`);

  ReactDOM.render(
    <React.StrictMode>
      {app}
    </React.StrictMode>,
    document.getElementById('it-forest-value-calculator')
  );
};

export { run };
