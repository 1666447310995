import { makeObservable, observable } from 'mobx';
import { AppPage, PageModel } from './Page';
import { userData } from '../components/ActiveComponent/Card';
import Valuation from './Valuation';
import Browser from './Browser';
import { api } from '../API';
import axios, { AxiosError } from 'axios';

class App {
  private _activeUser?: userData = undefined;
  private _valuation: Valuation | undefined;
  @observable
  loading: boolean = false;
  @observable
  estateId: string = '';

  constructor() {
    makeObservable(this);
  }
  browser = new Browser<AppPage>(new PageModel())

  init = () => {
    this.browser.page = { p: 'landing' };
  }

  get activeUser() { return this._activeUser; };
  set activeUser(value: userData | undefined) { this._activeUser = value; };
  get clearActiveUser() { return this._activeUser = undefined; }

  get valuationData() { return this._valuation; }
  set valuationData(value: Valuation | undefined) { this._valuation = value; };


  disabledButton = () => !new RegExp(/^([0-9]+)-([0-9]+)-([0-9]+)-([0-9]+)$/).test(this.estateId);

  getValuation = async () => {
    try {
      app.loading = true;
      const valuation = await api.getValuation(this.estateId);
      app.valuationData = new Valuation(valuation.data);
      app.browser.page = { p: 'result' };
    } catch (err: any | AxiosError) {
      if (axios.isAxiosError(err)) {
        this.estateId = '';
        err.response?.status.toString() === '503'
          ? app.browser.page = { p: 'EstateServerError' }
          : app.browser.page = { p: 'EstateNotFound' };
      }
    } finally {
      app.loading = false;
    }
  }
}

export const app = new App();
