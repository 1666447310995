import { DuetGrid, DuetGridItem, DuetHeading } from '@duetds/react';
import './index.scss';
import { HBox, VBox } from '@simosol/boxes';

import PaperIcon from '../../../../svg/paperIcon.svg';
import HouseIcon from '../../../../svg/houseIcon.svg';
import SunWithHouseIcon from '../../../../svg/sunWithHouseIcon.svg';
import { useMediaQuery } from '../../../Utils';
import Tooltip from '../../Tooltip';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  loogwood: number,
  residue: number,
}

const ShareMaterialsDiagram = (props: Props) => {
  const { loogwood, residue } = props;
  const isMobile = useMediaQuery('(max-width: 575px)');

  return (
    <div className={'shareMaterialsRoot'}>
      <DuetHeading className={'shareMaterialHeader'} level="h3">
        {LangKey.ResultPageDiagramsShareMaterialsDiagramTitle.t()}
      </DuetHeading>
      <DuetGrid className={'shareMaterialsMargin'} distribution="space-around" alignment={'center'}>
        <DuetGridItem className={'normalizedPadding'}>
          <VBox className={'itemRow'}>
            <img className={'iconStyle'} src={process.env.REACT_APP_PUBLIC_URL + PaperIcon}/>
            <Tooltip absolute right={isMobile ? -5 : -20} top={isMobile ? 70 : 80} tooltipText={LangKey.TooltipsIngredientFirstEl.t()} position={-70}/>
            <HBox className={'shareMaterialsItem'}>
              <DuetHeading level={isMobile ? 'h5' : "h4"} weight={'semibold'}>{LangKey.ResultPageDiagramsShareMaterialsDiagramFirstData.t()}</DuetHeading>
            </HBox>
            <DuetHeading level={isMobile ? 'h3' : 'h2'}>{`${(100 - Math.round(loogwood) - Math.round(residue)).toFixed()}%`}</DuetHeading>
          </VBox>
        </DuetGridItem>
        <DuetGridItem>
          <VBox className={'itemRow'}>
            <img className={'iconStyle'} src={process.env.REACT_APP_PUBLIC_URL + HouseIcon}/>
            <Tooltip absolute right={isMobile ? -5 : -20} top={isMobile ? 70 : 80} tooltipText={LangKey.TooltipsIngredientSecondEl.t()} position={-150}/>
            <HBox className={'shareMaterialsItem'}>
              <DuetHeading level={isMobile ? 'h5' : "h4"} weight={'semibold'}>{LangKey.ResultPageDiagramsShareMaterialsDiagramSecondData.t()}</DuetHeading>
            </HBox>
            <DuetHeading level={isMobile ? 'h3' : 'h2'}>{`${loogwood.toFixed()}%`}</DuetHeading>
          </VBox>
        </DuetGridItem>
        <DuetGridItem>
          <VBox className={'itemRow'}>
            <img className={'iconStyle'} src={process.env.REACT_APP_PUBLIC_URL + SunWithHouseIcon}/>
            <Tooltip absolute right={isMobile ? -5 : -20} top={isMobile ? 70 : 80} tooltipText={LangKey.TooltipsIngredientThirdEl.t()}/>
            <HBox className={'shareMaterialsItem'}>
              <DuetHeading level={isMobile ? 'h5' : "h4"} weight={'semibold'}>{LangKey.ResultPageDiagramsShareMaterialsDiagramThirdData.t()}</DuetHeading>
            </HBox>
            <DuetHeading level={isMobile ? 'h3' : 'h2'}>{`${residue.toFixed()}%`}</DuetHeading>
          </VBox>
        </DuetGridItem>
      </DuetGrid>
    </div>
  )
}

export default ShareMaterialsDiagram;
