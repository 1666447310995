import {
  DuetButton,
  DuetGrid,
  DuetGridItem,
  DuetHeading,
} from '@duetds/react';
import './index.scss';
import { VBox } from '@simosol/boxes';
import { LangKey } from '../../../lang/LangKey';

const EstateServerError = () => {

  return (
    <>
      <div className={'estateErrorRoot'}>
        <DuetGrid className={'estateErrorContactForm'} alignment="center" responsive>
          <DuetGridItem className={'estateErrorInfoWrapper'} fill>
            <VBox className={'estateErrorTitleWrapper'} gap={8} align={'center'}>
              <DuetHeading className={'estateErrorAddInformation'} level={'h4'} margin={'auto'}>
                {LangKey.EstateServerErrorTitle.t()}
              </DuetHeading>
              <DuetHeading className={'estateErrorAddInformation'} level={'h4'} margin={'auto'}>
                {LangKey.EstateServerErrorTitleAdd.t()}
              </DuetHeading>
              <DuetHeading className={'estateErrorAddInformation'} level={"h4"}>
                {LangKey.EstateServerErrorAddInfo.t()}
              </DuetHeading>
              <DuetGridItem className={'estateErrorButton'}>
                <DuetButton
                  variation={"secondary"}
                  className={'duet-background-data-15 duet-radius-pill'}
                  color={"color-primary-lightest"}
                  wrapping={"none"}
                  fixed
                  url={LangKey.ContactUs.t()}
                >
                  {LangKey.AppButtonsContactUs.t()}
                </DuetButton>
              </DuetGridItem>
            </VBox>
          </DuetGridItem>
          <DuetGridItem className={'estateErrorImageWrapper'} fill>
            <img className={'estateErrorBottomImage'} src={process.env.REACT_APP_PUBLIC_URL + '/image/mainBack.png'} alt={'wood'}/>
          </DuetGridItem>
        </DuetGrid>
      </div>
    </>
  );
};

export default EstateServerError;
