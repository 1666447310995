import * as React from 'react';
import './App.css';
import { DuetLayout } from "@duetds/react";
import Valuation from './models/Valuation';
import IndividualHeader from './components/IndividualHeader';
import { useMediaQuery } from './components/Utils';
import { observer } from 'mobx-react-lite';
import Pages from './components/Pages/Pages';
import { app } from './models/App';
import Footer from './components/Footer';

const App = observer(() => {
  const [valuation, setValuation] = React.useState<Valuation>();

  const isMobile = useMediaQuery('(min-width: 768px)');
  const footerHide = (
    !isMobile && (
      app.browser.page.p === 'EstateNotFound'
      || app.browser.page.p === 'EstateServerError'
      || app.browser.page.p === 'landing'
    )
  ) || app.loading;

  let valData = valuation;

  React.useEffect(() => {
    setValuation(valData)
  }, [valData]);

  return (
    <div className={"App"}>
      <IndividualHeader/>
      <DuetLayout margin={'none'}>
        <div slot={"main"}>
          <Pages/>
        </div>
      </DuetLayout>
      <Footer hideFooter={footerHide} />
    </div>
  );
});

export default App;
