import axios, { AxiosPromise, Method } from 'axios';
import { DValuation } from './models/Valuation';

class API {
  /**
   * Base function for creating API methods
   */
  public apiMethodBase = <T = {}>(url: string, method: Method, data?: {}) => {
    const headers = {
      'X-Api-Key': 'klclr4sbi5oy1uipg6elmsytgxftjrmo',
      // 'Accept-Language': window.setLocalesForCalculator,
      'Accept-Language': 'fi',
    };
    const request: AxiosPromise<T> = axios({
      url,
      method,
      data,
      headers
    });
    return request;
  }

  public apiMethod = <T>(route: string, method: Method, data?: {}) => {
    const apiURL = process.env.REACT_APP_API_DOMAIN;
    return this.apiMethodBase<T>(`${apiURL}${route}`, method, data);
  }

  public getValuation = (estateId: string) =>
    this.apiMethod<DValuation>('/valuation', 'POST', {
      "format": "LähiTapiola",
      "estate_id": estateId
    })
}

const apiErrorMessage = (e: Error) => {
  return  e.message ?? '__UNKNOWN_ERROR__';
};

const api = new API();

export { api, apiErrorMessage };
