import { Features, geomToGeoJSON } from '../GeoUtils';
import { Feature } from 'geojson';
import Stand, { DStand } from './Stand';

export type DValuation = {
  estate_municipality: string,
  estate_name: string,
  timber_volume: number,
  estate_area: number,
  estate_geom: string,
  stand_geoms: DStand[],
  forest_value: number,
  reference_value: number,
  volume_distribution: Distribution[],
  age_structure: AgeStructure[],
  urgent_operations: UrgentOperations[],
  logwood_percentage: number,
  pulpwood_percentage: number,
  residue_percentage: number,
};

export type Distribution = {
  species: string,
  volume: number,
  percentage: number,
};

export type AgeStructure = {
  age_class: string,
  percentage: number,
};

export type UrgentOperations = {
  name: string,
  area: number,
  cost: number
};

export default class Valuation {
  private readonly _data: DValuation;
  private readonly _geoJSON: Feature<Features> | undefined;
  stands: Stand[] = [];

  constructor(data: DValuation) {
    this._data = data;
    if (data.estate_geom) this._geoJSON = geomToGeoJSON(data.estate_geom);
    this.stands = this._data?.stand_geoms.map(s => new Stand(s)) ?? [];
  }

  get estate_municipality() { return this._data.estate_municipality; }
  get age_structure() { return this._data.age_structure; }
  get volume_distribution() { return this._data.volume_distribution; }
  get urgent_operations() { return this._data.urgent_operations; }
  get logwood_percentage() { return this._data.logwood_percentage; }
  get residue_percentage() { return this._data.residue_percentage; }
  get estate_area() { return this._data.estate_area; }
  get timber_volume() { return this._data.timber_volume; }
  get forest_value() { return this._data.forest_value; }
  get reference_value() { return this._data.reference_value; }
  get estate_name() { return this._data.estate_name; }

  get estateGeom() {
    return this._geoJSON;
  }
};
