import { DuetGridItem, DuetHeading } from '@duetds/react';
import { HBox } from '@simosol/boxes';
import './index.scss';
import LocationIcon from '../../../svg/location.svg';
import CoinIcon from '../../../svg/coin_1.svg';
import WoodIcon from '../../../svg/wood_1.svg';
import AreaIcon from '../../../svg/local_ha.svg';
import Valuation from '../../../models/Valuation';
import { userData } from '../Card';
import {
  // separatorForHectars,
  separatorForNormalizeMoneyDisplay,
  separatorForNormalNumberDisplay,
  useMediaQuery
} from '../../Utils';
import { observer } from 'mobx-react-lite';

export type forestRefData = {
  estateArea: number;
  timberVolume: number;
  forestValue: number;
  referenceValue: number;
};

type Props = {
  user?: userData;
  forestRefData: forestRefData;
  valuation?: Valuation;
  marginTop?: string;
};

const MainDataRow = observer((props: Props) => {
  const { user, forestRefData, valuation, marginTop } = props;
  const isMobile = useMediaQuery('(min-width: 920px)');
  const guestUser = user === undefined;

  const formatOptionsOne = {style:"decimal", maximumFractionDigits:1};
  const formatterHectares = new Intl.NumberFormat("en-US", formatOptionsOne);

  const splitMoneyData = separatorForNormalizeMoneyDisplay(forestRefData.forestValue.toFixed(0));
  const splitTimberData = separatorForNormalNumberDisplay(forestRefData.timberVolume.toFixed(0));
  const splitEstateArea = formatterHectares.format(forestRefData.estateArea).replace(',', ' ');

  return (
    <>
      <HBox gap={isMobile ? 8 : 4} className={'mainDataWrapper'} style={{ marginTop: marginTop ? marginTop : 0 }}>
        <DuetGridItem className={'mobilePadding'}>
          <HBox gap={isMobile ? 8 : 4} align={'center'}>
            <img src={process.env.REACT_APP_PUBLIC_URL + LocationIcon}/>
            <DuetHeading level={isMobile ? "h4" : "h6"}>{valuation?.estate_municipality}</DuetHeading>
          </HBox>
        </DuetGridItem>
        <DuetGridItem className={'mobilePadding'}>
          <HBox gap={isMobile ? 8 : 4} align={'center'}>
            <img src={process.env.REACT_APP_PUBLIC_URL + CoinIcon}/>
            <DuetHeading level={isMobile ? "h4" : "h6"}>{`${splitMoneyData} €`}</DuetHeading>
          </HBox>
        </DuetGridItem>
        <DuetGridItem className={'mobilePadding'}>
          <HBox gap={isMobile ? 8 : 4} align={'center'}>
            <img src={process.env.REACT_APP_PUBLIC_URL + WoodIcon}/>
            <DuetHeading level={isMobile ? "h4" : "h6"}>{`${splitTimberData} m³`}</DuetHeading>
          </HBox>
        </DuetGridItem>
        <DuetGridItem className={guestUser ? 'mobilePadding' : 'hideDataMobile'}>
          <HBox gap={isMobile ? 8 : 4} align={'center'}>
            <img src={process.env.REACT_APP_PUBLIC_URL + AreaIcon}/>
            <DuetHeading level={isMobile ? "h4" : "h6"}>{`${splitEstateArea} ha`}</DuetHeading>
          </HBox>
        </DuetGridItem>
        <DuetGridItem className={guestUser ? 'hideUserIcon' : 'hideDataMobile'}>
          <HBox gap={isMobile ? 8 : 4} align={'center'}>
            {user?.icon &&  <img src={ process.env.REACT_APP_PUBLIC_URL + user?.icon }/>}
            <DuetHeading level={isMobile ? "h4" : "h6"}>{user?.text.t()}</DuetHeading>
          </HBox>
        </DuetGridItem>
      </HBox>
      <HBox className={'mobileDataView'}>
        <DuetGridItem className={guestUser ? 'hideUserIcon' : 'hideDataDesktop'}>
          <HBox className={guestUser ? 'hideUserIcon' : undefined} gap={isMobile ? 8 : 4} align={'center'}>
            <img src={process.env.REACT_APP_PUBLIC_URL + AreaIcon}/>
            <DuetHeading
              level={isMobile ? "h4" : "h6"}>{`${splitEstateArea} ha`}</DuetHeading>
          </HBox>
        </DuetGridItem>
        <DuetGridItem className={guestUser ? 'hideUserIcon' : 'hideDataDesktop'}>
          <HBox className={'printUser'} gap={isMobile ? 8 : 4} align={'center'} style={{ display: guestUser ? ' none' : 'block' }}>
            {user?.icon && <img src={process.env.REACT_APP_PUBLIC_URL + user?.icon}/>}
            <DuetHeading level={isMobile ? "h4" : "h6"} margin={'auto'}>{user?.text.t()}</DuetHeading>
          </HBox>
        </DuetGridItem>
      </HBox>
    </>
  )
});

export default MainDataRow;
