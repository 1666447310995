import { DuetHeading } from '@duetds/react';
import './index.scss';
import { HBox, VBox } from '@simosol/boxes';
import FirstTree from '../../../../svg/firstTree.svg';
import SecondTree from '../../../../svg/secondTree.svg';
import ThirdTree from '../../../../svg/thirdTree.svg';
import { Distribution } from '../../../../models/Valuation';
import { observer } from 'mobx-react-lite';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  treeData: Distribution[],
  isMobile?: boolean,
}

const TreeDiagramSection = observer((props: Props) => {
  const { treeData, isMobile } = props;
  const localizationLabel = [
    LangKey.ResultPageDiagramsDiagramLabelManty.t(),
    LangKey.ResultPageDiagramsDiagramLabelKuusi.t(),
    LangKey.ResultPageDiagramsDiagramLabelLehtipuu.t(),
  ];

  return (
    <div className={'diagramRoot'}>
      <DuetHeading className={'diagramHeader'} level={isMobile ? 'h2' : 'h3'}>{LangKey.ResultPageDiagramsTreeDiagramTitle.t()}</DuetHeading>
          <HBox className={'diagramContainer'}>
            <VBox className={'firstTypeOfTree'}>
              <img className={treeData[0].percentage > 50 ? 'bigTree' : 'smallTree'} src={process.env.REACT_APP_PUBLIC_URL + FirstTree}/>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} margin={'none'}>{`${treeData[0].percentage.toFixed(0)}%`}</DuetHeading>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} weight={'semibold'}>
                {localizationLabel[0].t()}
              </DuetHeading>
            </VBox>
            <VBox className={'twoTypeOfTree'}>
              <img className={treeData[1].percentage > 50 ? 'bigTree' : 'smallTree'} src={process.env.REACT_APP_PUBLIC_URL + SecondTree}/>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} margin={'none'}>{`${treeData[1].percentage.toFixed(0)}% `}</DuetHeading>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} weight={'semibold'}>
                {localizationLabel[1].t()}
              </DuetHeading>
            </VBox>
            <VBox className={"threeTypeOfTree"}>
              <img className={treeData[2].percentage > 50 ? 'bigTree' : 'smallTree'} src={process.env.REACT_APP_PUBLIC_URL + ThirdTree}/>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} margin={'none'}>{`${(treeData[2].percentage).toFixed(0)}%`}</DuetHeading>
              <DuetHeading level={isMobile ? 'h2' : 'h3'} weight={'semibold'}>
                {localizationLabel[2].t()}
              </DuetHeading>
            </VBox>
          </HBox>
    </div>
  )
});

export default TreeDiagramSection;
