import { makeAutoObservable, observable } from 'mobx';

export type PageLanding = { p: 'landing' };
export type PageResult = { p: 'result' };
export type PageEstates = { p: 'estates' };
export type PageEstateNotFound = { p: 'EstateNotFound' };
export type PageEstateServerError = { p: 'EstateServerError' };



export type AppPage = PageLanding | PageResult | PageEstates | PageEstateNotFound | PageEstateServerError;

export class PageModel {
  @observable
  page: AppPage = { p: 'landing' };

  constructor() {
    makeAutoObservable(this);
  }
}

export default AppPage;
