import {
  DuetButton,
  DuetGrid,
  DuetGridItem,
  DuetHeading,
} from '@duetds/react';
import './index.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { HBox, VBox } from '@simosol/boxes';

import LocationIcon2 from '../../../svg/location_2.svg';

import MainDataRow, { forestRefData } from '../../ActiveComponent/MainDataRow';
import CardWithInfo from '../../ActiveComponent/CardWithInfo';
import FinanceSection from '../../ActiveComponent/FinanceSection';
import TreeDiagramSection from '../../ActiveComponent/TreeDiagramSection/TreeDiagram';
import NextFiveYearsDiagram from '../../ActiveComponent/TreeDiagramSection/NextFiveYearsDiagram';
import YourForestDiagram from '../../ActiveComponent/TreeDiagramSection/YourForestDiagram';
import ShareMaterialsDiagram from '../../ActiveComponent/TreeDiagramSection/ShareMaterialsDiagram';
import { separatorForNormalizeMoneyDisplay, useMediaQuery } from '../../Utils';
import useMapStyle from '../../../Map/useStyle';
import useControl from '../../../Map/useControl';
import useBounds from '../../../Map/useBounds';
import useStands from '../../../Map/useStands';
import useMap from '../../../Map/useMap';
import ThreeIcon from '../../../svg/threeIcon.svg';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';
import { USERS_ROLE } from '../../../userRole';
import { ESTATE_VALUE_FACTOR } from '../../../Config';

const ResultPage = () => {
  const user = USERS_ROLE.find(user => user.id === app.activeUser?.id);
  const valuation = app.valuationData;

  if (!valuation) {
    app.browser.page = { p: 'landing' };
    return null;
  }

  const isMobile = useMediaQuery('(min-width: 768px)');
  const isSmallMobile = useMediaQuery('(min-width: 575px)');

  const forestData = valuation.age_structure;
  const treeData = valuation.volume_distribution;
  const urgentData = valuation.urgent_operations;
  const materialData = {
    loogwood: valuation.logwood_percentage ?? 0,
    residue: valuation.residue_percentage ?? 0
  };

  const forestReferences: forestRefData = {
    estateArea: valuation.estate_area ?? 0,
    timberVolume: valuation.timber_volume ?? 0,
    forestValue: (valuation.forest_value ?? 0) * ESTATE_VALUE_FACTOR,
    referenceValue: valuation.reference_value ?? 0
  };

  const { map, mapContainer } = useMap();
  const isStyleLoad = useMapStyle(map)
  useControl(map);
  useBounds(map, valuation.stands);
  useStands(map, valuation.stands, isStyleLoad);

  return (
    <>
      <HBox className={'tipsResultWrapper'} align={'center'}>
        <img className={'threeIconStyle'} src={process.env.REACT_APP_PUBLIC_URL + ThreeIcon} alt={'tips_three'}/>
        <DuetHeading className={'tipsResultStyle'} level={"h4"}>{LangKey.ResultPageTips.t()}
        </DuetHeading>
      </HBox>
      <div className={'mainAdress'}>
        <DuetHeading margin={'none'} level={isMobile ? 'h4' : 'h2'}>{LangKey.ResultPageEstateName.t()}</DuetHeading>
        <DuetHeading level={isMobile ? 'h4' : 'h2'}>{`№${valuation.estate_name}`}</DuetHeading>
      </div>

      <MainDataRow user={user} forestRefData={forestReferences} valuation={valuation} />

      {mapContainer}

      <div className={'buttonContainer'}>
        <DuetButton
          className={'duet-background-data-15 duet-radius-pill'}
          color={'color-primary-lightest'}
          variation={'secondary'}
          onClick={() => app.browser.page = {p: 'landing'}}
          wrapping={'auto'}
          fixed
        >
          {LangKey.AppButtonsBackToMain.t()}
        </DuetButton>
      </div>
      <HBox className={'addressWithIcon'} gap={8}>
        <img className={'locationPrint'} src={process.env.REACT_APP_PUBLIC_URL + LocationIcon2} alt={'location_icon'}/>
        <div>
          <DuetHeading level={isMobile ? 'h3' : 'h6'} margin={'none'}>{LangKey.ResultPageEstateName.t()}</DuetHeading>
          <DuetHeading level={isMobile ? 'h3' : 'h6'}>{`№${valuation.estate_name}`}</DuetHeading>
        </div>
      </HBox>

      <DuetGrid alignment={'center'} className={'infoCardGrid'} distribution="center">
        <DuetGridItem className={'infoCardMargin'}>
          <CardWithInfo withPadding header={LangKey.ResultPageFinanceCardOneTitle.t()} mainText={LangKey.ResultPageFinanceCardOneInfo.t()} amount={`${separatorForNormalizeMoneyDisplay(forestReferences.forestValue.toFixed(0))} €`}/>
        </DuetGridItem>
        <DuetGridItem className={'mobileCentering'} style={{marginTop: isMobile ? 0 : '-16px' }}>
          <CardWithInfo header={LangKey.ResultPageFinanceCardTwoTitle.t()} mainText={LangKey.ResultPageFinanceCardTwoInfo.t()} amount={`${separatorForNormalizeMoneyDisplay(forestReferences.referenceValue.toFixed(0))} €`}/>
        </DuetGridItem>
      </DuetGrid>

      <FinanceSection percent={3} forestDataPercent={forestReferences.forestValue} activeUser={user}/>

      <VBox className={'separatorStyle'}>
        <DuetHeading className={'footerTextStyle'} margin={'none'} level={"h5"} color={'color-gray-lightest'}>
          {LangKey.FooterInfo.t()}
        </DuetHeading>
        <DuetButton className={'footerTermOfUse'} margin={'none'} padding={'none'} variation={'plain'} color={'color-gray-lightest'} url={LangKey.TermOfUse.t()}>
          <a className={'linkTermOfUse'}>{LangKey.FooterTerms.t()}</a>
        </DuetButton>
      </VBox>

      <MainDataRow user={user} forestRefData={forestReferences} valuation={valuation} marginTop={'32px'} />

      {!isMobile ? (
        <VBox className={'mobileDiagramContainer'}>
          <TreeDiagramSection treeData={treeData} isMobile={isSmallMobile}/>
          <YourForestDiagram forestData={forestData}/>
          <ShareMaterialsDiagram
            loogwood={materialData.loogwood}
            residue={materialData.residue}
          />
          <NextFiveYearsDiagram urgentData={urgentData}/>
        </VBox>
      ) : (
        <HBox className={'mt24'}>
          <VBox className={'leftDiagramContainer'}>
            <TreeDiagramSection treeData={treeData}/>
            <NextFiveYearsDiagram urgentData={urgentData} />
          </VBox>
          <VBox className={'rightDiagramContainer'} align={'start'}>
            <YourForestDiagram forestData={forestData}/>
            <ShareMaterialsDiagram
              loogwood={materialData.loogwood}
              residue={materialData.residue}
            />
          </VBox>
        </HBox>
      )}
      <HBox className={'printButton'}>
      <DuetButton
        className={'duet-background-data-15 duet-radius-pill'}
        variation="secondary"
        color={'color-primary-lightest'}
        onClick={() => window.print()}
      >
        {LangKey.AppButtonsPrintTotPDF.t()}
      </DuetButton>
      </HBox>

      <VBox className={'infoBlockWithButtons'}>
        <VBox className={'footerTextWrapper'}>
          <DuetHeading level="h4">{LangKey.ContactUsSectionTitle.t()}</DuetHeading>
        </VBox>
        <HBox className={'joinUsWrapper'}>
        <HBox>
          <DuetHeading className={'joinUsText'} level="h5">{LangKey.ContactUsSectionInfo.t()}</DuetHeading>
        </HBox>
        <HBox className={'joinUsTextWithButtons'}>
          <DuetButton
            className={'joinUsButtons duet-background-data-08 duet-letter-spacing-heading duet-radius-pill'}
            size={'small'}
            color={'color-primary-lightest'}
            variation={'primary'}
            padding={'auto'}
            url={LangKey.ContactUs.t()}
            onClick={e => {
              e.currentTarget.loading = true;
              e.currentTarget.variation = 'negative'
            }}
          >
            {LangKey.AppButtonsContactUs.t()}
          </DuetButton>
          <DuetButton
            className={'joinUsButtons duet-background-data-08 duet-letter-spacing-heading duet-radius-pill'}
            size={'small'}
            color={'color-primary-lightest'}
            variation={'primary'}
            padding={'auto'}
            url={LangKey.AboutUs.t()}
            onClick={e => {
              e.currentTarget.loading = true;
              e.currentTarget.variation = 'negative'
            }}
          >
            {LangKey.AppButtonsGetToKnow.t()}
          </DuetButton>
        </HBox>
        </HBox>
      </VBox>
    </>
  );
};

export default ResultPage;
