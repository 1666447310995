import React from 'react';

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = React.useState(false);

    React.useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
};

export const separatorForNormalizeMoneyDisplay = (data: string) => {

    const result = data.length >= 9 && data.length < 11 ?
      Math.floor(Number(data)/1000000) + ' ' + Math.ceil((Number(data)/1000)).toString().substr(3) + ' 000'
      :
      data.length >= 8 && data.length < 9 ?
        Math.floor(Number(data)/1000000) + ' ' + Math.ceil((Number(data)/1000)).toString().substr(2) + ' 000'
      :
      (data.length >= 7 && data.length < 8) ?
      Math.floor(Number(data)/1000000) + ' ' + Math.ceil((Number(data)/1000)).toString().substr(1) + ' 000'
      :
        (data.length > 3 && data.length <= 6) ?
        Math.ceil(Number(data)/1000) + ' ' + ' 000'
        :
        (data.length < 5) && (data.length > 3) ?
          Math.ceil(Number(data)/1000)+ ' 000'
          :
          (data.length > 0) && (data.length <=3) ? data.toString().trim()
            :
            Math.round(Number(data))+ '';
    return result
};

export const separatorForNormalNumberDisplay = (data: string) => {
    const whatIsType: number = data.length >= 6 && (data.length === 6 ? 1 : data.length === 7 ? 2 : 3) || 0;

    const result = data.length > 5 && data.length <= 9 ? data.toString().substr(0, whatIsType-1).concat(' ') +
      data.toString().substring(whatIsType-1, data.length).toString().split(/(\d{3})/).join(' ').trim()
      :
      (data.length > 3 && data.length <= 5) ?
        data.toString().substr(0,data.length === 5 ? 2 : 1).concat(' ') + data.toString().substr(data.length === 5 ? 2 : 1, data.toString().length)
        :
        (data.length < 5) && (data.length > 3) ?
          data.toString().substr(0,1).concat(' ') + data.toString().substr(1, data.toString().length)
          :
          (data.length > 0) && (data.length <=3) ? data.toString().trim()
            :
            data.toString().split(/(\d{3})/).join(' ').trim();
    return result
};
