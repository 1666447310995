import { Feature, MultiPolygon, Polygon } from 'geojson';
import proj4 from 'proj4';
import { parse as wktParse } from 'wellknown';

export type Features = Polygon | MultiPolygon;

export const geomToGeoJSON = (geom: string): Feature<Features> => {
  return {
    type: 'Feature',
    geometry: inverse3067(wktParse(geom) as Features),
    properties: {},
  };
};

export const inverse3067 = (geometry: Features): Features => {
  switch (geometry.type) {
    case 'Polygon': {
      geometry.coordinates = geometry.coordinates.map(positionArr =>
        positionArr.map(position => proj4('EPSG:3067').inverse(position)));
      break;
    }
    case 'MultiPolygon': {
      geometry.coordinates = geometry.coordinates.map(positionArr =>
        positionArr.map(position =>  position.map(pos => proj4('EPSG:3067').inverse(pos))));
      break;
    }
  }
  return geometry;
};
