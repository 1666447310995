import i18next from 'i18next';
import { default as XHR } from 'i18next-xhr-backend';

declare global {
  interface String {
    t(p?: object): string;
  }
}

String.prototype.t = function (this: string): string {
  return this;
};

export class Lang {

  constructor() {}

  init = async (path: string) => {
    return i18next.use(XHR).init(
      {
        fallbackLng: 'en',
        debug: false,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          loadPath: path,
          crossDomain: true,
        },
        nsSeparator: '|',
      },
      () => {
        String.prototype.t = function (this: string, p?: object): string {
          return i18next.t(this.valueOf(), p) as string;
        };
      },
    );
  }
}
