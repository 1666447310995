import { observer } from 'mobx-react-lite';
import { app } from '../../models/App';
import LandingPage from './LandingPage';
import EstateChoosePage from './EstateChoosePage';
import EstateNotFound from './EstateNotFound';
import ResultPage from './ResultPage';
import EstateServerError from './EstateErrorr';

const Pages = observer(() => {
  const pg = app.browser.page;
  let page = null;
  if (pg.p === 'landing') {
    page = <LandingPage activeUser={app.activeUser?.id}/>
  }
  if (pg.p === 'estates') {
    page = <EstateChoosePage activeUser={app.activeUser?.id ?? 0}/>;
  }
  if (pg.p === 'EstateNotFound') {
    page = <EstateNotFound />;
  }
  if (pg.p === 'result') {
    page = <ResultPage/>;
  }
  if (pg.p === 'EstateServerError') {
    page = <EstateServerError />;
  }
  return page;
});

export default Pages;
