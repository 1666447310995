import * as React from 'react';
import { Map } from 'mapbox-gl';

const useMapStyle = (map: Map | undefined) => {
  const [isStyleLoad, setIsStyleLoad] = React.useState(false);
  React.useEffect(
    () => {
      if (!map) return;
      const updateStyleCounter = () => {
        setIsStyleLoad(true);
      };
      map.on('style.load', updateStyleCounter);
      return () => {
        setIsStyleLoad(false);
        map.off('style.load', updateStyleCounter);
      };
    },
    [map],
  );

  return isStyleLoad;
};

export default useMapStyle;
