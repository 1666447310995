import React from 'react';
import * as turf from '@turf/turf';
import { Map } from 'mapbox-gl';
import Stand from '../models/Stand';

const useBounds = (
  map?: Map,
  stands?: Stand[]
) => {
  React.useEffect(
    () => {
      if (!map || !stands) return;
      map.fitBounds(
        turf.bbox(
          turf.combine(
            turf.featureCollection([...stands.map(s => s.geoJSON)]) as any)
        ) as [number, number, number, number],
        { padding: 20, linear: true },
      );
    },
    [map, stands],
  );
}

export default useBounds;
