import { DuetButton, DuetHeading } from '@duetds/react';
import { VBox } from '@simosol/boxes';
import './index.scss';
import { app } from '../../models/App';
import { useMediaQuery } from '../Utils';
import { LangKey } from '../../lang/LangKey';

type Props = {
  hideFooter?: boolean;
};

const Footer = (props: Props) => {
  const { hideFooter } = props
  const minimizeButton = useMediaQuery('(max-width: 375px)');
  const showButton = app.browser.page.p === 'result';

  return (
    <>
      <VBox className={'footerStyle'} style={{display: hideFooter ? 'none' : 'inherit'}}>
        <DuetHeading className={'footerTextStyle'} margin={'none'} level={"h5"} color={'color-gray-lightest'}>
          {LangKey.FooterInfo.t()}
        </DuetHeading>
        <DuetButton className={'footerTermOfUse'} margin={'none'} padding={'none'} variation={'plain'} color={'color-gray-lightest'} url={LangKey.TermOfUse.t()} >
          <a className={'linkTermOfUse'}>{LangKey.FooterTerms.t()}</a>
        </DuetButton>
        <div style={{ position: 'relative', display: showButton ? 'inherit' : 'none' }}>
          <div className={'footerButton'}>
            <DuetButton color='color-gray-lightest' size={minimizeButton ? 'small' : 'medium'} url={LangKey.AboutUs.t()}>
              {LangKey.FooterButton.t()}
            </DuetButton>
          </div>
        </div>
      </VBox>
    </>
  )
};

export default Footer;