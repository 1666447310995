import React from 'react';
import { Map, NavigationControl } from 'mapbox-gl';

const useControl = (map?: Map) => {
  React.useEffect(
    () => {
      if (!map) return;
      map.addControl(new NavigationControl({showCompass: false}));
    },
    [map],
  );
}

export default useControl;
