import { DuetCard, DuetButton, DuetHeading, DuetGrid } from '@duetds/react';
import './index.scss';
import { useMediaQuery } from '../../Utils';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';
import { USERS_ROLE } from '../../../userRole';

export type userData = {
  id: number;
  text: string
  description: string;
  background: string;
  icon: string;
  active: boolean;
  percent: number;
};

type Props = {
  user: userData;
  activeUser?: number;
};

const Card = (props: Props) => {
  const { user, activeUser } = props;
  const isMobile = useMediaQuery('(min-width: 768px)');

  const hoverCardColor = () => {
    let hoverBorderColor = '';
    (user.id === activeUser && user.background) === "#F0E7D1" ?
      hoverBorderColor = "#806211" :
      (user.id === activeUser && user.background) === "#CCEBF5" ?
        hoverBorderColor = "#00A1D4" :
        (user.id === activeUser && user.background) === "#FDDBEB" ?
          hoverBorderColor = "#DE2362" :
          (user.id === activeUser && user.background) === "#D1EEDB" ?
            hoverBorderColor = "#00875A" : '';
    return hoverBorderColor;
  }

  const onButtonClick = () => {
    app.activeUser = USERS_ROLE[user.id - 1];
    app.browser.page = { p: 'estates' };
  }

  return (
    <DuetCard
      className={'card'}
      padding="x-small"
      style={{
        backgroundColor: user.background,
        boxSizing: 'border-box',
        border: user.id === activeUser ? `2px solid ${hoverCardColor()}` : '2px',
        margin: isMobile ? '8px' : '0'
      }}
      background={user.background}>
      <DuetGrid distribution="center" alignment="center" direction="vertical">
        <DuetHeading level={isMobile ? "h3" : "h2"} color={'black'}>{user.text.t()}</DuetHeading>
        <img className={'cardIcon'} src={process.env.REACT_APP_PUBLIC_URL + user.icon}/>
        <DuetHeading className={'cardHeading'} level={isMobile ? "h5" : "h6"}>{user.description.t()}</DuetHeading>
        <DuetButton
          className={'duet-background-data-15 duet-radius-pill'}
          onClick={onButtonClick}
          size={'medium'}
          color={"color-primary-lightest"}
          wrapping={"auto"}
          variation={"secondary"}
          fixed
        >
          {`${LangKey.UserCardIAm.t()} ${user.text.t()}`}
        </DuetButton>
      </DuetGrid>
    </DuetCard>
  )
};

export default Card;
