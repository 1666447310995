import { LangKey } from './lang/LangKey';
import BrownAvatar from './svg/brown-avatar.svg';
import BlueAvatar from './svg/blue-avatar.svg';
import PinkAvatar from './svg/pink-avatar.svg';
import GreenAvatar from './svg/green-avatar.svg';

export const USERS_ROLE = [
  {
    id: 1,
    text: LangKey.UserCardFirstCard,
    description: LangKey.UserCardFirstInfo,
    background: "#F0E7D1",
    icon: BrownAvatar,
    active: false,
    percent: 1.85,
  },
  {
    id: 2,
    text: LangKey.UserCardSecondCard,
    description: LangKey.UserCardSecondInfo,
    background: "#CCEBF5",
    icon: BlueAvatar,
    active: false,
    percent: 2.85,
  },
  {
    id: 3,
    text: LangKey.UserCardThirdCard,
    description: LangKey.UserCardThirdInfo,
    background: "#FDDBEB",
    icon: PinkAvatar,
    active: false,
    percent: 1.3,
  },
  {
    id: 4,
    text: LangKey.UserCardFourCard,
    description: LangKey.UserCardFourInfo,
    background: "#D1EEDB",
    icon: GreenAvatar,
    active: false,
    percent: 2.85,
  },
]
