import * as React from 'react';
import { default as useResizeAware } from 'react-resize-aware';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Map as BMap, Map as MBMap, MapboxOptions } from 'mapbox-gl';

type ResizeListenerProps = {
  map?: BMap;
};

const ResizeListener = (props: ResizeListenerProps) => {
  const { map } = props;
  const [resizeListener, sizes] = useResizeAware();
  React.useEffect(
    () => {
      if (!map) return;
      map.resize();
    },
    [map, sizes.width, sizes.height],
  );
  return <>{resizeListener}</>;
};

// style: 'mapbox://styles/mapbox/streets-v11',
const useMap = (options?: MapboxOptions) => {
  const [map, setMap] = React.useState<MBMap>();
  const opts = options ?? {
    container: 'map',
    accessToken: 'pk.eyJ1Ijoic2ltb3NvbCIsImEiOiJja2g1MnpoMWcwNGZkMnlvaDUxc3BtZWprIn0.WHhuJVpo20TygbABwf0Edw',
    style: 'mapbox://styles/mapbox/outdoors-v11',
    center: [23.118181255140982, 60.39141937660817],
    zoom: 12,
    preserveDrawingBuffer: true
  };

  React.useEffect(
    () => {
      setMap(new MBMap(opts));
    },
    [],
  );

  const container = (
    <>
      <ResizeListener map={map}/>
      <div className={'map'} id={'map'}/>
    </>
  );

  return { map, mapContainer: container };
};

export default useMap;
