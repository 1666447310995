import React from 'react';
import { Map } from 'mapbox-gl';
import Stand from '../models/Stand';

const useStands = (
  map: Map | undefined,
  stands: Stand[] | undefined,
  isStyleLoad: boolean,
) => {

  React.useEffect(
    () => {
      if (!map ||!stands || !isStyleLoad) return;
      enum LayerParams {
        standFill = 'standFill',
        standBorder = 'standBorder',
        sourceId = 'stands',
      }
      const sourceId = LayerParams.sourceId;
      const standFillsLayerId = LayerParams.standFill;
      const standBordersLayerId = LayerParams.standBorder;

      map.addSource(
        sourceId,
        {
          type: 'geojson',
          promoteId: 'id',
          data: {
            type: 'FeatureCollection',
            features: stands.map(s => {
              let newGeoJSON = {...s.geoJSON};
              newGeoJSON.properties = {
                ...s,
                fillColor: '#849B7A',
                lineColor: '#509C33',
                lineWidth: 2,
              };
              return newGeoJSON;
            }),
          },
        }
      );
      map.addLayer({
        id: standFillsLayerId,
        type: 'fill',
        source: sourceId,
        layout: {},
        paint: {
          'fill-color': ['get', 'fillColor'],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.2,
            0.1
          ]
        }
      });
      map.addLayer({
        id: standBordersLayerId,
        type: 'line',
        source: sourceId,
        layout: {
          'line-cap': 'butt',
          'line-join': 'round',
        },
        paint: {
          'line-width': ['get', 'lineWidth'],
          'line-color': ['get', 'lineColor'],
          'line-opacity': 1,
        }
      });

      return () => {
        if (map.getLayer(standBordersLayerId)) {
          map.removeLayer(standBordersLayerId);
        }
        if (map.getSource(standBordersLayerId)) {
          map.removeSource(standBordersLayerId);
        }
        if (map.getLayer(standFillsLayerId)) {
          map.removeLayer(standFillsLayerId);
        }
        if (map.getSource(standFillsLayerId)) {
          map.removeSource(standFillsLayerId);
        }
        if (map.getSource(sourceId)) {
          map.removeSource(sourceId);
        }
      };
    },
    [map, stands, isStyleLoad],
  );

}

export default useStands;
