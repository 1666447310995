import { Feature } from 'geojson';
import { Features, geomToGeoJSON } from '../GeoUtils';

export type DStand = {
  stand_id: string,
  stand_no: number,
  geom: string,
};

export default class Stand {
  private readonly _data: DStand;
  private readonly _geoJSON: Feature<Features>;

  constructor(data: DStand) {
    this._data = data;
    this._geoJSON = geomToGeoJSON(data.geom);
  }
  get id() { return this._data.stand_id; }
  get label() { return this._data.stand_no; }
  get geoJSON() { return this._geoJSON; }
}
