import {
  DuetButton,
  DuetGrid,
  DuetGridItem,
  DuetHeading,
  DuetInput,
  DuetSpinner
} from '@duetds/react';
import './index.scss';
import TwoIcon from '../../../svg/twoIcon.svg';
import { DuetInputEvent } from '@duetds/components/lib/types/components/duet-input/duet-input';
import * as React from 'react';
import { HBox } from '@simosol/boxes';
import { observer } from 'mobx-react-lite';
import { app } from '../../../models/App';
import { LangKey } from '../../../lang/LangKey';
import { USERS_ROLE } from '../../../userRole';
import { ESTATE_NOT_FOUND_CONTACT } from '../../../Config';

type Props = {
  activeUser: number;
};

const EstateChoosePage = observer((props: Props) => {
  const { activeUser } = props;
  const guestUser = activeUser === 0;
  const mainRef = React.useRef(null);

  const onChange = (event: CustomEvent<DuetInputEvent>) => {
    app.estateId = event.detail.value;
  };

  return (
    <>
      {app.loading ? (
          <div className={'spinnerStyle'}>
            <DuetHeading className={'spinnerText'} level={"h2"}>{LangKey.EstateChooseLoading.t()}</DuetHeading>
            <DuetSpinner size={'large'} color="primary"/>
          </div>
        )
        : undefined}
      <div className={app.loading ? 'hideEstateData' : 'estateChooseRoot'}>
        {guestUser ? (
          <HBox className={'userDataRoot'} gap={8} align={'center'} />
        ) : (
          <HBox className={'userDataRoot'} gap={8} align={'center'}>
            <img className={'userDataIcon'} alt={'user_icon'} src={process.env.REACT_APP_PUBLIC_URL + USERS_ROLE[activeUser - 1].icon}/>
            <DuetHeading className={'estateUserText'} level={"h4"}>{USERS_ROLE[activeUser - 1].text.t()}</DuetHeading>
          </HBox>
        )}
        <DuetGrid className={'contactForm'} alignment="stretch" responsive>
          <DuetGridItem className={'inputWrapper'} fill>
            <HBox className={'inputTitleWrapper'} gap={8} align={'center'}>
              <img className={'twoIconStyle'} alt={'tips_two'} src={process.env.REACT_APP_PUBLIC_URL + TwoIcon}/>
              <DuetHeading className={'inputTitle'} level={"h4"}>{LangKey.EstateChooseTips.t()}</DuetHeading>
            </HBox>
            <DuetGrid className={'inputGrid'} alignment={"center"} direction={'vertical'}>
              <DuetGridItem>
                <DuetInput
                  id={'mainFocus'}
                  onKeyPress={(e) => e.key === 'Enter' && !app.disabledButton ? console.log('ENTER') : ''}
                  onDuetChange={onChange}
                  className={'contactInput'}
                  type={"text"}
                  placeholder={LangKey.EstateChooseEstateNum.t()}
                  disallow-pattern={"[^0-9,-]"}
                  labelHidden={true}
                  ref={mainRef}
                />
              </DuetGridItem>
              <DuetGridItem className={'buttonWithTips'}>
                <DuetButton
                  onClick={() => app.getValuation()}
                  variation={"secondary"}
                  className={'duet-background-data-15 duet-radius-pill'}
                  color={"color-primary-lightest"}
                  wrapping={"none"}
                  fixed
                  disabled={app.disabledButton()}
                >
                  {LangKey.AppButtonsEstateSearchButton.t()}
                </DuetButton>
                <DuetButton
                  className={'estateContactLink'}
                  variation={'plain'}
                  inputMode={'url'}
                  url={ESTATE_NOT_FOUND_CONTACT}
                >
                  {LangKey.EstateChooseContactLink.t()}
                </DuetButton>
              </DuetGridItem>
            </DuetGrid>
          </DuetGridItem>
          <DuetGridItem className={'imageWrapper'} fill>
            <img className={'bottomImage'} src={process.env.REACT_APP_PUBLIC_URL + '/image/mainBack.png'} alt={'wood'}/>
          </DuetGridItem>
        </DuetGrid>
      </div>
    </>
  );
});

export default EstateChoosePage;
