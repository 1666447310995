import { DuetButton, DuetHeading } from '@duetds/react';
import './index.scss';
import { HBox, VBox } from '@simosol/boxes';
import MoneyIcon from '../../../svg/money.svg';
import { separatorForNormalNumberDisplay, useMediaQuery } from '../../Utils';
import Tooltip from '../Tooltip';
import { userData } from '../Card';
import { LangKey } from '../../../lang/LangKey';

type Props = {
  percent: number,
  forestDataPercent: number;
  activeUser?: userData;
}

const FinanceSection = (props: Props) => {
  const { percent, forestDataPercent, activeUser } = props;
  const isMobile = useMediaQuery('(max-width: 767px)');

  const firstMultiplePercent = (forestDataPercent / 100) * percent;
  const userMultiplePercent = (forestDataPercent / 100) * (activeUser?.percent || 0);

  return (
    <>
      <div className={'percent'}>
        <DuetHeading className={'financeHeaderText'} margin={'none'} level={isMobile ? "h4" : "h3"}>
          {LangKey.ResultPageFinanceSectionTitle.t()}
          <Tooltip left={8} tooltipText={LangKey.TooltipsPercent.t()} position={-150}/>
        </DuetHeading>
        <div className={'center'}>
          <div className={'circle'}>{`${percent.toFixed(1)}%`}</div>
        </div>
      </div>

      <HBox className={activeUser ? 'mobileFinanceView' : 'noUserView'}>
        <VBox className={activeUser ? 'leftFinanceInfo' : 'centeredFinanceInfo'}>
          <DuetHeading className={activeUser ? 'fixedHeight120' : ''}
                       level={"h3"}>{LangKey.ResultPageFinanceSectionFirstCardTitle.t()}</DuetHeading>
          <DuetHeading className={activeUser ? 'financeTextLeft fixedHeight180' : 'noUserFinanceText'} level="h3"
                       weight={'semibold'}>{LangKey.ResultPageFinanceSectionFirstCardSubTitle.t()}</DuetHeading>
          <div className={'moneyAndIconContainer fixedHeight120'}>
            <DuetHeading level="h1">
              {`${separatorForNormalNumberDisplay(firstMultiplePercent.toFixed(0))} €/${LangKey.ResultPageFinanceSectionAmountText.t()}`}
            </DuetHeading>
          </div>
          <div className={'moneyAndIconContainer fixedHeight120'}>
            <img className={'moneyLeftIcon'} src={process.env.REACT_APP_PUBLIC_URL + MoneyIcon}/>
            <img className={'moneyRightIcon'} src={process.env.REACT_APP_PUBLIC_URL + MoneyIcon}/>
          </div>
          <DuetHeading className={activeUser ? 'leftLastText' : 'noUserLastText'} level={isMobile ? "h5" : "h4"} weight={'semibold'}>
            {LangKey.ResultPageFinanceSectionFirstCardFooter.t()}
          </DuetHeading>
        </VBox>
        <VBox className={activeUser ? 'rightFinanceInfo' : 'noUserRightFinanceInfo'}>
          <DuetHeading className={'fixedHeight120'}
                       level="h3">{LangKey.ResultPageFinanceSectionSecondCardTitle.t()}</DuetHeading>
          <DuetHeading className={'financeTextRight fixedHeight180'} level="h3"
                       weight={'semibold'}>{LangKey.ResultPageFinanceSectionSecondCardSubTitle.t({user: activeUser?.text.t()})}</DuetHeading>
          <div className={'moneyAndIconContainer fixedHeight120'}>
            <DuetHeading
              level="h1">{`${separatorForNormalNumberDisplay(userMultiplePercent.toFixed(0))} €/${LangKey.ResultPageFinanceSectionAmountText.t()}`}</DuetHeading>
          </div>
          <div className={'moneyAndIconContainer fixedHeight120'}>
            <img className={'oneMoneyIcon'} src={process.env.REACT_APP_PUBLIC_URL + MoneyIcon}/>
          </div>
          <DuetHeading className={'marginTop16'} level={isMobile ? "h5" : "h4"} weight={'semibold'}>
            {LangKey.ResultPageFinanceSectionSecondCardFooter.t()}
          </DuetHeading>
        </VBox>
      </HBox>
      <DuetButton className={'financeButton duet-background-data-15 duet-radius-pill'}
                  padding={'auto'}
                  size={'small'}
                  variation="secondary" color={'color-primary-lightest'}
                  url={LangKey.ContactUs.t()}
                  onClick={e => {
                    e.currentTarget.loading = true;
                    e.currentTarget.variation = 'negative'
                  }}
      >
        {LangKey.AppButtonsContactUs.t()}
      </DuetButton>
    </>
  )
}

export default FinanceSection;
