export class LangKey {
  static HeaderTitle = 'header.title';
  static HeaderSubtitle = 'header.subtitle';
  static MainPageTitle = 'mainPage.title';
  static MainPageTips = 'mainPage.tips';
  static MainPageAdditionalInfo = 'mainPage.addInfo';
  static UserCardFirstCard = 'userCard.firstCard';
  static UserCardFirstInfo = 'userCard.firstCardInfo';
  static UserCardSecondCard = 'userCard.secondCard';
  static UserCardSecondInfo = 'userCard.secondCardInfo';
  static UserCardThirdCard = 'userCard.thirdCard';
  static UserCardThirdInfo = 'userCard.thirdCardInfo';
  static UserCardFourCard = 'userCard.fourCard';
  static UserCardFourInfo = 'userCard.fourCardInfo';
  static UserCardIAm = 'userCard.iAm';
  static EstateChooseLoading = 'estateChoose.loading';
  static EstateChooseTips = 'estateChoose.tips';
  static EstateChooseEstateNum = 'estateChoose.estateNum';
  static EstateChooseInfoMask = 'estateChoose.infoMask';
  static EstateChooseContactLink = 'estateChoose.contactLink';
  static AppButtonsEstateSearchButton = 'appButtons.estateSearchButton';
  static EstateNotFoundTitle = 'estateNotFound.title';
  static EstateNotFoundError = 'estateNotFound.error';
  static EstateServerErrorTitle = 'estateServerError.title';
  static EstateServerErrorTitleAdd = 'estateServerError.titleAdd';
  static EstateServerErrorAddInfo = 'estateServerError.addInfo';

  static ResultPageTips = 'resultPage.tips';
  static ResultPageEstateName = 'resultPage.estateName';
  static ResultPageFinanceCardOneTitle = 'resultPage.financeCardOneTitle';
  static ResultPageFinanceCardOneInfo = 'resultPage.financeCardOneInfo';
  static ResultPageFinanceCardTwoTitle = 'resultPage.financeCardTwoTitle';
  static ResultPageFinanceCardTwoInfo = 'resultPage.financeCardTwoInfo';
  static ResultPageFinanceSectionTitle = 'resultPage.financeSection.title';
  static ResultPageFinanceSectionFirstCardTitle = 'resultPage.financeSection.firstCardTitle';
  static ResultPageFinanceSectionFirstCardSubTitle = 'resultPage.financeSection.firstCardSubTitle';
  static ResultPageFinanceSectionFirstCardFooter = 'resultPage.financeSection.firstCardFooter';
  static ResultPageFinanceSectionAmountText = 'resultPage.financeSection.amountText';
  static ResultPageFinanceSectionSecondCardTitle = 'resultPage.financeSection.secondCardTitle';
  static ResultPageFinanceSectionSecondCardSubTitle = 'resultPage.financeSection.secondCardSubTitle';
  static ResultPageFinanceSectionSecondCardFooter = 'resultPage.financeSection.secondCardFooter';

  static ResultPageDiagramsTreeDiagramTitle = 'resultPage.diagrams.treeDiagramTitle';
  static ResultPageDiagramsUserForestDiagramTitle = 'resultPage.diagrams.userForestDiagramTitle';
  static ResultPageDiagramsUserForestDiagramSub = 'resultPage.diagrams.userForestDiagramSub';
  static ResultPageDiagramsUserForestDiagramFirstData = 'resultPage.diagrams.userForestDiagramFirstData';
  static ResultPageDiagramsUserForestDiagramSecondData = 'resultPage.diagrams.userForestDiagramSecondData';
  static ResultPageDiagramsUserForestDiagramThirdData = 'resultPage.diagrams.userForestDiagramThirdData';
  static ResultPageDiagramsShareMaterialsDiagramTitle = 'resultPage.diagrams.shareMaterialsDiagramTitle';
  static ResultPageDiagramsShareMaterialsDiagramFirstData = 'resultPage.diagrams.shareMaterialsDiagramFirstData';
  static ResultPageDiagramsShareMaterialsDiagramSecondData = 'resultPage.diagrams.shareMaterialsDiagramSecondData';
  static ResultPageDiagramsShareMaterialsDiagramThirdData = 'resultPage.diagrams.shareMaterialsDiagramThirdData';
  static ResultPageDiagramsFiveYearsDiagramTitle = 'resultPage.diagrams.fiveYearsDiagramTitle';
  static ResultPageDiagramsFiveYearsDiagramFirstData = 'resultPage.diagrams.fiveYearsDiagramFirstData';
  static ResultPageDiagramsFiveYearsDiagramSecondData = 'resultPage.diagrams.fiveYearsDiagramSecondData';
  static ResultPageDiagramsFiveYearsDiagramThirdData = 'resultPage.diagrams.fiveYearsDiagramThirdData';
  static ResultPageDiagramsFiveYearsDiagramNone = 'resultPage.diagrams.fiveYearsDiagramNone';
  static ResultPageDiagramsDiagramLabelManty = 'resultPage.diagrams.diagramsLabelManty';
  static ResultPageDiagramsDiagramLabelKuusi = 'resultPage.diagrams.diagramsLabelKuusi';
  static ResultPageDiagramsDiagramLabelLehtipuu = 'resultPage.diagrams.diagramsLabelLehtipuu';
  static ResultPageDiagramsDiagramLabelTaimikonhoito = 'resultPage.diagrams.diagramsLabelTaimikonhoito';
  static ResultPageDiagramsDiagramLabelEnsiharvennukset = 'resultPage.diagrams.diagramsLabelEnsiharvennukset';
  static ResultPageDiagramsDiagramLabelUudistustoimenpiteet = 'resultPage.diagrams.diagramsLabelUudistustoimenpiteet';

  static ContactUsSectionTitle = 'contactUsSection.title';
  static ContactUsSectionInfo = 'contactUsSection.info';

  static FooterInfo = 'footer.info';
  static FooterTerms = 'footer.terms';
  static FooterButton = 'footer.footerButton';

  static AppButtonsGuestButton = 'appButtons.guestButton';
  static AppButtonsContactUs = 'appButtons.contactUs';
  static AppButtonsGetToKnow = 'appButtons.getToKnow';
  static AppButtonsBackToMain = 'appButtons.backToMain';
  static AppButtonsPrintTotPDF = 'appButtons.printToPDF';

  static TooltipsResultLeftFinance = 'tooltips.resultLeftFinance';
  static TooltipsResultRightFinance = 'tooltips.resultRightFinance';
  static TooltipsPercent = 'tooltips.percentTooltip';
  static TooltipsAgeFirstEl = 'tooltips.ageFirstEl';
  static TooltipsAgeSecondEl = 'tooltips.ageSecondEl';
  static TooltipsAgeThirdEl = 'tooltips.ageThirdEl';
  static TooltipsIngredientFirstEl = 'tooltips.ingredientFirstEl';
  static TooltipsIngredientSecondEl = 'tooltips.ingredientSecondEl';
  static TooltipsIngredientThirdEl = 'tooltips.ingredientThirdEl';
  static TooltipsNextFirstEl = 'tooltips.nextFirstEl';
  static TooltipsNextSecondEl = 'tooltips.nextSecondEl';
  static TooltipsNextThirdEl = 'tooltips.nextThirdEl';

  static TermOfUse = 'termOfUse.url';
  static AboutUs = 'aboutUs.url';
  static ContactUs = 'contactUs.url';
}
