import './index.scss'
import { DuetHeading } from '@duetds/react';
import { HBox } from '@simosol/boxes';
import MainLogo from '../../svg/mainLogo.svg';
import MainLogoSV from '../../svg/svMainLogo.svg';
import { useMediaQuery } from '../Utils';
import { app } from '../../models/App';
import { LangKey } from '../../lang/LangKey';

const IndividualHeader = () => {

  const isMobile = useMediaQuery('(min-width: 768px)');
  // @ts-ignore
  const logo = window.setLocalesForCalculator === 'sv' ? MainLogoSV : MainLogo;
  return (
    <div className={'headerRoot'}>
      <div className={'headerWraper'}>
        <HBox className={'headerText'}>

          <DuetHeading level={isMobile ? "h1" : "h2"} color={'color-gray-lightest'} margin={'none'}>{LangKey.HeaderTitle.t()}</DuetHeading>
          <DuetHeading style={{marginBottom: 8}} level={"h2"} color={'color-gray-lightest'} weight={'semibold'} margin={'none'}>{LangKey.HeaderSubtitle.t()}</DuetHeading>
        </HBox>
        <HBox className={'logoWrapper'}>
          <img className={'logoStyle'} src={process.env.REACT_APP_PUBLIC_URL + logo} onClick={() => app.browser.page = { p: 'landing' }}/>
        </HBox>
      </div>
    </div>
  )
};
export default IndividualHeader;
