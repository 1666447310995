import './index.scss';
import InfoIcon from '../../../svg/infoIcon.svg';

export type TooltipsData = {
  tooltipText?: string;
  tooltipTextPos?: number;
};

type Props = {
  left?: number;
  top?: number;
  right?: number;
  bottom?: number;
  tooltipText?: string;
  absolute?: boolean;
  position?: number;
}

const Tooltip = (props: Props) => {
  const { left, top, right, bottom, tooltipText, absolute, position } = props;

  return (
    <div className={'tooltip'}
         style={{
           left: left ?? 'auto',
           top: top ?? 'auto',
           right: right ?? 'auto',
           bottom: bottom ?? 'auto',
           position: absolute ? 'absolute' : 'relative'
         }}>
      <img className={'tooltip'} src={process.env.REACT_APP_PUBLIC_URL + InfoIcon} alt={'tooltip'}/>
      <span className={'tooltipText'} style={{ left: position ?? -210 }}>{tooltipText}</span>
    </div>
  )
};

export default Tooltip;
