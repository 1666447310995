import { DuetGrid, DuetGridItem, DuetHeading } from '@duetds/react';
import { VBox, HBox } from '@simosol/boxes';
import './index.scss';
import DotIcon from '../../../../svg/dotIcon.svg';
import React from 'react';
import { separatorForNormalNumberDisplay } from '../../../Utils';
import Tooltip, { TooltipsData } from '../../Tooltip';

type Props = {
  icon: string;
  label: string;
  percent?: number;
  money?: string;
  addInfo?: string;
  isMoney?: boolean;
  moneyPercent?: number;
  tooltipsData?: TooltipsData;
};

const DiagramItem = (props: Props) => {
  const { icon, label, percent, money, addInfo, isMoney, moneyPercent, tooltipsData } = props;

  const diagramLineDrawer = (data: number) => {
    const dotLength = Math.trunc(data/5);
    const dotArr: React.ReactNode[] = new Array(dotLength > 0 ? dotLength : 1).fill(<img className={'diagramDotStyle'} src={process.env.REACT_APP_PUBLIC_URL + DotIcon} alt={'s'}/>);
    return dotArr;
  }

  return (
    <DuetGrid alignment="center">
      <DuetGridItem>
        <img className={'diagramIconStyle'} src={process.env.REACT_APP_PUBLIC_URL + icon}/>
      </DuetGridItem>
      <DuetGridItem>
        <VBox className={'diagramRowItem'}>
          <HBox className={'diagramTextStyle'}>
            <DuetHeading style={{ width: 'fit-content' }} level='h6'>{addInfo ? label.toUpperCase() + ' ' + addInfo : label}</DuetHeading>
            {addInfo
              ? <Tooltip bottom={20} tooltipText={tooltipsData?.tooltipText} />
              : <Tooltip left={8} bottom={8} tooltipText={tooltipsData?.tooltipText} position={tooltipsData?.tooltipTextPos} />
            }
          </HBox>
          <HBox className={'diagramDataWrapper'}>
            {diagramLineDrawer(isMoney ? (moneyPercent ?? 0) : (percent ?? 0))}
            <DuetHeading level="h5" style={{marginLeft: 4}}>
              {isMoney ? separatorForNormalNumberDisplay(money ?? '0') + ' €' : percent?.toFixed(0) + ' %'}
            </DuetHeading>
          </HBox>
        </VBox>
      </DuetGridItem>
    </DuetGrid>
  )
};

export default DiagramItem;
