import { DuetGrid, DuetGridItem, DuetHeading } from '@duetds/react';
import './index.scss';
import { HBox, VBox } from '@simosol/boxes';
import DiagramItem from '../DiagramItems';

import DiagramIcon1 from '../../../../svg/diagramIcon_1.svg';
import DiagramIcon2 from '../../../../svg/diagramIcon_2.svg';
import DiagramIcon3 from '../../../../svg/diagramIcon_3.svg';
import { AgeStructure } from '../../../../models/Valuation';
import { LangKey } from '../../../../lang/LangKey';

type Props = {
  forestData: AgeStructure[],
}

const YourForestDiagram = (props: Props) => {
  const { forestData } = props;

  return (
    <div className={'yourForestRoot'}>
      <HBox style={{ alignItems: 'baseline' }}>
        <VBox className={'yourForestHeaderText'}>
          <DuetHeading level={"h3"} margin={'none'}>{LangKey.ResultPageDiagramsUserForestDiagramTitle.t()}</DuetHeading>
          <DuetHeading level="h6">{LangKey.ResultPageDiagramsUserForestDiagramSub.t()}</DuetHeading>
        </VBox>
      </HBox>
      <VBox className={'yourForestContainer'}>
        <DuetGrid distribution="left" direction="vertical">
          <DuetGridItem className={'yourForestItemStyle'}>
            <DiagramItem
              icon={DiagramIcon1} label={LangKey.ResultPageDiagramsUserForestDiagramFirstData.t()}
              percent={forestData[0].percentage}
              tooltipsData={{tooltipText: LangKey.TooltipsAgeFirstEl.t(), tooltipTextPos: -150}}
            />
          </DuetGridItem>
          <DuetGridItem className={'yourForestItemStyle'}>
            <DiagramItem
              icon={DiagramIcon2} label={LangKey.ResultPageDiagramsUserForestDiagramSecondData.t()}
              percent={forestData[1].percentage}
              tooltipsData={{tooltipText: LangKey.TooltipsAgeSecondEl.t(), tooltipTextPos: -150}}
            />
          </DuetGridItem>
          <DuetGridItem className={'yourForestItemStyle'}>
            <DiagramItem
              icon={DiagramIcon3} label={LangKey.ResultPageDiagramsUserForestDiagramThirdData.t()}
              percent={forestData[2].percentage}
              tooltipsData={{tooltipText: LangKey.TooltipsAgeThirdEl.t(), tooltipTextPos: -120}}
            />
          </DuetGridItem>
        </DuetGrid>
      </VBox>
    </div>
  )
}

export default YourForestDiagram;
